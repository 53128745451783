<template>
  <b-col class="py-3">
    <Breadcrumb :items="[{label: $t('Giftcode'), click: () => $router.push({name: 'GiftCodeIndex'})}, {label: $t('Used codes history')}]"/>
    <div class="search-form">
      <div class="icon">
        <b-icon icon="search"/>
      </div>
      <input type="search" v-model="keyword" :placeholder="$t('Search games')">
    </div>
    <div class="logs">
      <div class="log d-flex" v-for="(log, index) in logs" :key="index">
        <img :src="log.game.iconUrl.getUrl()" class="game-icon mr-3"/>
        <div class="log-content">
          <h3 class="code-name">{{log.name}}</h3>
          <div class="server-name"><strong class="mr-1">{{$t("Code used")}}:</strong>{{log.code}}</div>
          <div class="server-name"><strong class="mr-1">{{$t("Server")}}:</strong>{{log.server.name}}</div>
          <div class="role-name"><strong class="mr-1">{{$t("Character")}}:</strong>{{log.roleName}}</div>
          <div class="use-date">{{$toDateTimeString(log.usedDate)}}</div>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import giftcodeService from "@/services/giftcodeService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "History",
  data() {
    return {
      keyword: "",
      loading: false,
      logs: [],
      take: 20,
      skip: 0,
      draw: 0,
      ended: false,
      timeout: null
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$bridge.$on("scrollToBottom", this.loadData);
  },
  beforeDestroy() {
    this.$bridge.$off("scrollToBottom", this.loadData);
  },
  methods: {
    async loadData(renew = false) {
      if (renew) {
        this.ended = false;
        this.skip = 0;
        this.logs = [];
      }
      else {
        if (this.loading || this.ended)
          return;
      }

      this.loading = true;
      this.draw++;
      const response = await giftcodeService.getLogs({
        keyword: this.keyword,
        draw: this.draw,
        take: this.take,
        skip: this.skip
      });

      this.loading = false;
      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      const data = response.data;
      if (this.draw <= data.draw) {
        let logs = data.logs;
        const totalLogs = logs.length;
        this.logs = [...this.logs, ...logs];
        this.skip += totalLogs;
        this.ended = this.take > totalLogs;
      }
    }
  },
  watch: {
    keyword(newValue) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.timeout = setTimeout(() => this.loadData(true), 500);
    }
  }
}
</script>

<style scoped lang="scss">
.search-form {
  position: relative;

  .icon {
    position: absolute;
    color: gray;
    top: 11px;
    left: 12px;
  }

  input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    padding: 10px 0 10px 36px;
    background: #f4f4f4;
    border-radius: 100px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 13px;
  }
}

.logs {
  .log {
    padding: 15px 0;
    border-bottom: 1px solid #f4f5f7;

    .game-icon {
      height: 44px;
      width: 44px;
      object-fit: contain;
    }

    .log-content {
      flex: 1;
      font-size: 14px;
      color: #8e8e93;

      h3 {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 2px;
        color: #000;
      }

      strong {
        font-weight: 400;
      }

    }
  }
}
</style>
